import React from 'react'
import * as styles from './book.module.css'
import { Button } from '../button/button'
import { StaticImage } from 'gatsby-plugin-image'

export const Book = () => (
  <section className={styles.book}>
    <section className={styles.content}>
      <article className={styles.left}>
        <h1>
          Wizards use <span className={styles.accent}>Vim</span>
        </h1>
        <p>
          From the author that brought you the acclaimed series of niche and
          quirky JavaScript books, the half-elven ranger, deadly archer, master
          of the two-handed sword and dual scimitar wielder, protector of
          Veleiron, Falcon of the Crepuscle, silent death of the swamps of Zulk
          comes a <span className={styles.accent}>new gripping adventure </span>
          of epic proportions, love, betrayal and Vim.
        </p>
        <Button href="/wizards-use-vim/once-upon-a-time">
          Read online
        </Button>
        <Button href="https://leanpub.com/wizardsusevim">
          Buy now for 15$
        </Button>
        <p className={styles.sale}>
          50% off until launch. Wooot! Take my money!
        </p>
      </article>
      <article className={styles.right}>
        <div className={styles.img}>
          <a href="https://leanpub.com/wizardsusevim">
            <StaticImage
              src="./wizards-use-vim-book.png"
              title="Wizards use Vim book"
              alt="Wizards use Vim book"
              width={400}
              height={600}
              quality={100}
              loading="lazy"
              />
          </a>
        </div>
      </article>
    </section>
  </section>
)
