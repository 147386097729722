import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../wizardsusevimbook/Layout'
import * as styles from './wizards-use-vim.module.css'

import SEO from '../components/seo'
import { Home } from '../wizardsusevimbook/components/home/home'
import { Arcana } from '../wizardsusevimbook/components/arcana/arcana'
import { Pitch } from '../wizardsusevimbook/components/pitch/pitch'
import { Quote } from '../wizardsusevimbook/components/quote/quote'
import { Book } from '../wizardsusevimbook/components/book/book'
import { EmeraldGuard } from '../wizardsusevimbook/components/emerald-guard/emerald-guard'
import { Author } from '../wizardsusevimbook/components/author/author'

export default class WizardsUseVim extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout>
        <article className={styles.content}>
          <SEO
            date={new Date(2022, 12, 19)}
            dateModified={new Date(2022, 12, 19)}
            title={`Wizards Use Vim - The Book | ${siteTitle}`}
            keywords={[`vim`, `neovim`, `programming`]}
            faviconUrl="/wizards-use-vim/vim-icon.png"
          />
          <Home />
          <Arcana />
          <Pitch />
          <Quote
            author="Jaime González García"
            text="Vim is not easy. In this book, I've strived to remove all the obstacles I faced through years of using Vim to make it approachable to everyone"
          />
          <Book />
          <EmeraldGuard />
          <Author />
        </article>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query WizardsUseVimQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
