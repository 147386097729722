import React from 'react'
import * as styles from './author.module.css'

export const Author = () => (
  <section className={styles.author}>
    <div className={styles.image} />
    <article className={styles.about}>
      <h1>About the author</h1>
      <p>
        <a href="https://www.twitter.com/vintharas" target="blank_">
          Jaime González García
        </a>{' '}
        is a software engineer, author, speaker and defender of all things nerd.
      </p>

      <p>
        He works as a software engineer at{' '}
        <a
          href="https://www.linkedin.com/in/jaimegonzalezgarcia"
          target="blank_"
        >
          Google
        </a>
        , has published{' '}
        <a href="https://www.barbarianmeetscoding.com/books/" target="blank_">
          four books
        </a>
        , authored 100+ technical{' '}
        <a href="https://barbarianmeetscoding.com" target="blank_">
          articles
        </a>
        , speaks at conferences and meetups, and organizes events in the local
        development community in Stockholm, Sweden.
      </p>

      <p>
        Jaime loves spending time with his wife Malin and kids Teo and Li, creating
        stuff, reading fantasy and sci-fi, and lifting heaving things.
      </p>
    </article>
  </section>
)
