import React from 'react'
import * as styles from './pitch.module.css'

export const Pitch = () => (
  <section className={styles.pitch}>
    <section className={styles.content}>
      <section className={styles.left}>
        <h1>
          <span>Learn Vim,</span>
          <br />
          <span>
            have <span className={styles.accent}>more fun</span>{' '}
            coding,
          </span>
          <br />
          <span>
            become an <span className={styles.accent}>awesome</span>{' '}
            developer.
          </span>
        </h1>
        <p>
          There's programmers amongst us powerful beyond measure. Their skills
          so amazing as to resemble magic. They edit code at the speed of
          thought, they jump and fly across the source code, back and forth, an
          edit here. An edit there. Boom! Improving the code with surgical
          precision and manifesting their thoughts into code out of thin air.
          And you can become one of them.
        </p>
        <p>Learn Vim. Be one with the code.</p>
      </section>
      <section className={styles.right}>
        <aside className={styles.highlight}>
          <h2>From Beginner to Expert</h2>
          <p>
            Vim is infamous for its steep learning curve. Wizards use Vim
            demystifies Vim, smooths its learning curve and guides you all along
            the way to mastery. No previous Vim knowledge is required.
          </p>
        </aside>
        <aside className={styles.highlight}>
          <h2>With Tons of Exercises</h2>
          <p>
            A very important part of learning Vim effectively is practice.
            Wizards use Vim comes with tons of exercises and opportunities for
            practice.
          </p>
        </aside>
        <aside className={styles.highlight}>
          <h2>Fun and Fantastic</h2>
          <p>
            A fun experience placed in a whimsical world of fantasy where
            Wizards can shape the world around them through coding. Learn Vim.
            Save the World.
          </p>
        </aside>
      </section>
    </section>
  </section>
)
