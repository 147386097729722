// extracted by mini-css-extract-plugin
export var background = "home-module--background--acd95";
export var content = "home-module--content--30cb7";
export var fadein = "home-module--fadein--24b59";
export var footer = "home-module--footer--bc9db";
export var home = "home-module--home--63566";
export var left = "home-module--left--3751d";
export var pageHeight = "100vh";
export var right = "home-module--right--5c1e4";
export var sorceress = "home-module--sorceress--bdb3b";
export var titleImage = "home-module--title-image--05ccb";
export var wizard = "home-module--wizard--48809";