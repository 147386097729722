import React, { useState } from 'react'
import * as styles from './home.module.css'

import { randomFromSelection } from '../../../utils/random'
// import { ClientOnly } from '../../components/client-only/client-only'
// import { useInterval } from '../../useInterval'
import { StaticImage } from 'gatsby-plugin-image'

export const Home = () => {
  const [wizard, setWizard] = useState(getRandomWizard())

  // TODO: when I have more wizards
  // also make it possible to animate transitions
  // when switching between wizards
  //useInterval(() => {
  //setWizard(getRandomWizard(wizard))
  //}, 5000)

  return (
    <section className={styles.home}>
      <section className={styles.content}>
        <section className={styles.left}>
          <StaticImage
            src="./wizards-use-vim-title.png"
            title="Wizards Use Vim"
            alt="Wizards Use Vim"
            layout="fullWidth"
            quality={100}
            className={styles.titleImage}
            loading="eager"
            placeholder="none"
          />
          <p>
            Unleash the power of Vim. <br /> Become an awesome developer.
          </p>
        </section>
        <section className={styles.right}>
          <section
            className={`${styles.background} ${styles[wizard]}`}
          ></section>
        </section>
      </section>
    </section>
  )
}

function getRandomWizard(lastWizard = '') {
  const wizards = ['wizard', 'sorceress'].filter((w) => w !== lastWizard)
  return randomFromSelection(wizards)
}
