import React from 'react'
import * as styles from './quote.module.css'
import { StaticImage } from 'gatsby-plugin-image'

export const Quote = ({ text, author}) => (
  <aside className={styles.container}>
    <blockquote className={styles.quote}>
      <p>
        {text}
        <cite>- {author}</cite>
      </p>
      <footer>
        <StaticImage
          src="./avatar-jaime-002.jpg"
          title="Jaime Gonzalez Garcia"
          alt="Jaime Gonzalez Garcia"
          width={200}
          height={200}
          quality={100}
          loading="lazy"
        />
      </footer>
    </blockquote>
  </aside>
)
